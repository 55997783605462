import React, { useState } from 'react';
import styles from "./WarTable.module.css";

const HeaderMapping = {
    "playerName": "Speler",
    "kingdom": "Kingdom",
    "alliance": "Alliance",
    "damage": "Damage",
    "regen": "Regeneration",
    "kills": "Kills",
    "deaths": "Deaths",
    "captures": "Capture points"
}

const WarTable = ({ data }) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  const sortedData = [...data].sort((a, b) => {
    if (sortConfig.key !== null) {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
    }
    return 0;
  });

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  return (
    <table>
      <thead>
        <tr>
          {Object.keys(data[0]).map((key) => (
            <th key={key} onClick={() => requestSort(key)}>
              {HeaderMapping[key]}
              {sortConfig.key === key && (
                <span>{sortConfig.direction === 'asc' ? ' ↓' : ' ↑'}</span>
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {sortedData.map((item, index) => (
          <tr key={index}>
            {Object.keys(item).map((key) => (
              <>
                {
                    key === "playerName" ? (
                        <td key={key}>
                            <div className={styles.playerColumn}>
                                <img src={"https://mineskin.eu/helm/" + item[key]} alt={item[key]} />
                                <span>{item[key]}</span>
                            </div>
                        </td>
                    ) : (
                        <td key={key}>{item[key]}</td>
                    )
                }
              </>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default WarTable;
