import { useEffect, useState } from "react";
import * as skinview3d from "skinview3d";
import styles from "./PlayerSkinViewer.module.css";

const PlayerSkinViewer = (props) => {
    const [skinViewer, setSkinViewer] = useState(null);

    const { name } = props;

    useEffect(() => {
        const element = document.getElementById("canvas");
        const viewer = new skinview3d.SkinViewer({
            canvas: element,
            width: 700,
            height: 900,
            skin: "https://mineskin.eu/skin/" + name,
            enableControls: true,
            animation: new skinview3d.WalkingAnimation(),
            zoom: 0.5
        });
        setSkinViewer(viewer);
    }, [name]);

    const runAnimation = () => {
        skinViewer.animation = new skinview3d.RunningAnimation();
        setTimeout(() => {
            skinViewer.animation = new skinview3d.WalkingAnimation();
        }, 1000);
    };

    return (
        <div className={styles.skinViewer}>
            <canvas id="canvas" onDoubleClick={runAnimation}></canvas>
        </div>
    );
};

export default PlayerSkinViewer;