import { useParams } from "react-router-dom";
import KothWinnerViewer from "../../../components/KothWinnerViewer/KothWinnerViewer";
import KothStats from "../../../components/KothStats/KothStats";
import styles from "./KothLogs.module.css";

const KothLogs = () => {
    const { name } = useParams();

    return (
        <div className={styles.kothLogs}>
            <KothWinnerViewer name={name} />
            <KothStats name={name} />
        </div>
    );
};

export default KothLogs;