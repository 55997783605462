import {} from "../NavBar/NavBar.module.css";

const toggleNav = () => {
    let navbar = document.getElementById("navbar");
    let currentHeight = navbar.style.maxHeight;

    if (currentHeight === "" || currentHeight === "0px") {
        navbar.style.maxHeight = "18rem"
    }
    else {
        navbar.style.maxHeight = "0px"
    }
};

const NavBar = () => {
    return (
        <nav>
            <a href="https://www.majestumkingdoms.nl">
                <img src="/images/mk_icon.png" alt="Icon Majestum Kingdoms" />
                <span><b>M</b>AJESTUM <b>K</b>INGDOMS</span>
            </a>
            <i className="fa-solid fa-bars" onClick={toggleNav}></i>
            <ul id="navbar">
                <li>
                    <a href="https://discord.majestumkingdoms.nl">
                        <i className="fa-brands fa-discord"></i>
                        <span>Discord</span>
                    </a>
                </li>
                <li>
                    <a href="https://shop.majestumkingdoms.nl">
                        <i className="fa-solid fa-basket-shopping"></i>
                        <span>Store</span>
                    </a>
                </li>
                <li>
                    <a href="https://map.majestumkingdoms.nl">
                        <i className="fa-solid fa-map"></i>
                        <span>Map</span>
                    </a>
                </li>
                <li>
                    <a href="https://wiki.majestumkingdoms.nl">
                        <i className="fa-solid fa-book"></i>
                        <span>Wiki</span>
                    </a>
                </li>
                <li>
                    <a href="https://stats.majestumkingdoms.nl">
                        <i className="fa-solid fa-chart-column"></i>
                        <span>Stats</span>
                    </a>
                </li>
            </ul>
        </nav>
    );
};

export default NavBar;