import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';

// Layouts
import RootLayout from './layouts/RootLayout';
import PlayersLayout from './layouts/PlayersLayout';
import KothLayout from './layouts/KothLayout';
import WarLayout from './layouts/WarLayout';

// Pages
import Home from './pages/Home/Home';
import PlayerDetails from './pages/players/PlayerDetails/PlayerDetails';
import KothLogs from './pages/koth/KothLogs/KothLogs';
import WarLogs from './pages/war/WarLogs/WarLogs';
import NotFound from './pages/NotFound/NotFound';
import BattlegroundLogs from './pages/war/BattlegroundLogs/BattlegroundLogs';
import SiegeLogs from './pages/war/SiegeLogs/SiegeLogs';

const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<RootLayout />}>
        <Route index element={<Home />} />
        <Route path="players" element={<PlayersLayout />}>
            <Route index element={<Home />} />
            <Route path=":name" element={<PlayerDetails />} />
        </Route>
        <Route path="koth" element={<KothLayout />}>
            <Route index element={<Home />} />
            <Route path=":name" element={<KothLogs />} />
        </Route>
        <Route path="battleground" element={<WarLayout />}>
            <Route index element={<Home />} />
            <Route path=":name" element={<BattlegroundLogs />} />
        </Route>
        <Route path="siege" element={<WarLayout />}>
            <Route index element={<Home />} />
            <Route path=":name" element={<SiegeLogs />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    )
  )

const App = () => {
    return ( 
        <RouterProvider router={router} />
    );
};

export default App