import styles from "./WarHighlight.module.css"

const WarHighlight = (params) => {
    const { highlight, name } = params;
    const playerHead = "https://mineskin.eu/headhelm/" + name + "/100.png";

    return (
        <div className={styles.warHighlight}>
            <div>
                <img src={playerHead} alt={name} />
            </div>
            <div>
                <h3 className={styles.name}>{name}</h3>
                <p className={styles.highlight}>{highlight}</p>
            </div>
        </div>
    );
};

export default WarHighlight;