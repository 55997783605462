import { useParams } from "react-router-dom";
import WarWinnerViewer from "../../../components/WarWinnerViewer/WarWinnerViewer";
import WarStats from "../../../components/WarStats/WarStats";
import styles from "./BattlegroundLogs.module.css";

const BattlegroundLogs = () => {
    const { name } = useParams();

    return (
        <div className={styles.warLogs}>
            <WarWinnerViewer name={name} type="battleground" />
            <WarStats name={name} type="battleground" />
        </div>
    );
};

export default BattlegroundLogs;