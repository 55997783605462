import { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from "./PlayerSearchBox.module.css";

const PlayerSearchBox = () => {
    const [playerName, setPlayerName] = useState('');

    const changePlayerName = (event) => {
        setPlayerName(event.target.value)
    }

    return (
        <div className={styles.playerSearchBox}>
            <h2>Majestum Kingdoms Statistics</h2>
            <input 
                placeholder="Zoeken naar een speler..." 
                value={playerName} 
                onChange={(e) => changePlayerName(e)} 
            />
            <Link className={styles.button} to={'/players/' + playerName}>Toon Stats</Link>
        </div>
    );
};

export default PlayerSearchBox;
