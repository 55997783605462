import PlayerSearchBox from "../../components/PlayerSearchBox/PlayerSearchBox";
import styles from "./Home.module.css";

const Home = () => {
    return (
        <div className={styles.home}>
            <PlayerSearchBox />
        </div>
    );
};

export default Home;