import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <div className="not-found">
            <h2>Page not found</h2>
            <p>Deze pagina bestaat niet of is niet langer beschikbaar.</p>
            <Link to="/">Naar homepage</Link>
        </div>
    );
};

export default NotFound;