import styles from "./PlayerBadge.module.css"

const PlayerBadge = (params) => {
    const { badge } = params;
    const { name, description, season, tier, image } = badge;
    const tierClass = `tier${tier}`;

    return (
        <div className={styles.playerBadge}>
            <div className={styles[tierClass]}>
                <img src={image} alt={name} />
            </div>
            <div>
                <h3 className={styles.name}>{name}</h3>
                <p className={styles.description}>{description}</p>
                <p className={styles.season}>{season}</p>
            </div>
        </div>
    );
};

export default PlayerBadge;