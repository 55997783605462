import { useParams } from "react-router-dom";
import PlayerSkinViewer from "../../../components/PlayerSkinViewer/PlayerSkinViewer";
import PlayerStats from "../../../components/PlayerStats/PlayerStats";
import styles from "./PlayerDetails.module.css";

const PlayerDetails = () => {
    const { name } = useParams();

    return (
        <div className={styles.playerDetails}>
            <PlayerSkinViewer name={name} />
            <PlayerStats name={name} />
        </div>
    );
};

export default PlayerDetails;