import { Link } from "react-router-dom";
import PlayerBadge from "../PlayerBadge/PlayerBadge";
import styles from "./PlayerStats.module.css";
import { useEffect, useState } from "react";

const PlayerStats = ({ name }) => {
    const [player, setPlayer] = useState(null);

    useEffect(() => {
        const fetchPlayerData = async () => {
            try {
                const response = await fetch('https://api.majestumkingdoms.nl/api/players?name=' + name);
                if (!response.ok) {
                    throw new Error('Failed to fetch player data');
                }
                const jsonData = await response.json();
                setPlayer(jsonData);
            } catch (error) {
                console.error('Error fetching player data:', error);
                setPlayer({
                    "id": "00000000-0000-0000-0000-000000000000",
                    "name": name,
                    "general": null,
                    "seasonal": null,
                    "badges": []
                });
            }
        };

        fetchPlayerData();
    }, [name]);

    if (!player) {
        return (
            <div className={styles.loading}>
                <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                <p>Loading player data...</p>
            </div>
        );
    }

    return (
        <div>
            <Link to="/" className={styles.backLink}><i className="fa-solid fa-arrow-left"></i> Terug</Link>

            {
                player.general != null ? (
                    <>
                        <h1 className={styles.playerName}><span className={player.general.isOnline ? styles.online : ''}><i className="fa-solid fa-circle"></i></span> {name}</h1>
                        <p><em>Laatst ingelogd op {player.general.lastLogin}</em></p>
                        
                        <h2 className={styles.title}>General Stats</h2>
                        <p><b>Eerste login</b> {player.general.firstLogin}</p>
                        <p><b>Totale playtime</b> {player.general.totalPlaytime} {player.general.totalPlaytime > 1 ? "uren" : "uur"}</p>
                        <p><b>Totale aantal logins</b> {player.general.totalLogins} {player.general.totalLogins > 1 ? "launches" : "launch"}</p>
                        <p><b>Top login streak</b> {player.general.topLoginStreak} {player.general.topLoginStreak > 1 ? "dagen" : "dag"}</p>
                    </>
                ) : (
                    <>
                        <h1 className={styles.playerName}><span><i className="fa-solid fa-circle"></i></span> {name}</h1>
                        <p><em>Nog niet geregistreerd</em></p>
                        
                        <h2 className={styles.title}>General Stats</h2>
                        <p>Geen data</p>
                    </>
                )
            }

            <h2 className={styles.title}>Majestum Beta</h2>
            {
                player.seasonal != null ? (
                    <>
                        <p><b>Playtime</b> {player.seasonal.playtime} {player.seasonal.playtime > 1 ? "uren" : "uur"}</p>
                        <p><b>Kingdom</b> {player.seasonal.kingdom}</p>
                        <p><b>Kingdom rank</b> {player.seasonal.kingdomRank}</p>
                        <p><b>Alliance</b> {player.seasonal.alliance}</p>
                    </>
                ) : (
                    <p>Geen data</p>
                )
            }
            
            <h2 className={styles.title}>Badges</h2>
            {
                player.badges.length > 0 ? (
                    <div className={styles.playerBadges}>
                        {player.badges.map((badge, index) => (
                            <PlayerBadge key={`badge_${index}`} badge={badge} />
                        ))}
                    </div>
                ) : (
                    <p>Geen badges</p>
                )
            }
        </div>
    );
};

export default PlayerStats;