import styles from "./ThemeSwitch.module.css";
import { useState } from "react";

const themes = [
    {
        id: 1,
        name: "dark",
        icon: "fa-solid fa-moon"
    },
    {
        id: 2,
        name: "light",
        icon: "fa-solid fa-sun"
    }
];

const ThemeSwitch = () => {
    const [selectedTheme, setSelectedTheme] = useState(0);
    
    const changeTheme = () => {
        // Change theme in state
        let nextTheme = selectedTheme + 1;
        if (nextTheme === themes.length) nextTheme = 0;
        setSelectedTheme(nextTheme);

        // Update background
        let body = document.body;
        for (let i = 0; i < themes.length; i++) {
            let theme = themes[i];
            if (i === nextTheme) {
                if (!body.classList.contains(theme.name)) {
                    body.classList.add(theme.name);
                }
            }
            else {
                if (body.classList.contains(theme.name)) {
                    body.classList.remove(theme.name);
                }
            }
        }
    };

    return (
        <div className={styles.themeButton} onClick={changeTheme}>
            <i className={themes[selectedTheme].icon}></i>
        </div>
    );
}

export default ThemeSwitch;