import WarHighlight from "../WarHighlight/WarHighlight";
import WarTable from "../WarTable/WarTable";
import styles from "./WarStats.module.css";
import { useEffect, useState } from "react";

const WarStats = (params) => {
    const { name, type } = params;
    
    const [war, setWar] = useState(null);

    useEffect(() => {
        const fetchWarData = async () => {
            try {
                const apiUrl = 'https://api.majestumkingdoms.nl';
                //const apiUrl = 'https://localhost:44319';
                const response = await fetch(apiUrl + '/api/battles?name=' + name + '&type=' + type);
                if (!response.ok) {
                    throw new Error('Failed to fetch war data');
                }
                const jsonData = await response.json();
                setWar(jsonData);
            } catch (error) {
                console.error('Error fetching war data:', error);
                setWar({
                    territory: null
                });
            }
        };

        fetchWarData();
    }, [name, type]);

    if (!war) {
        return (
            <div className={styles.loading}>
                <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                <p>Loading war data...</p>
            </div>
        );
    }

    return (
        <div>
            {
                war.territory != null ? (
                    <>
                        <h1 className={styles.warTitle}>Siege war: {war.territory}</h1>
                        <p><em>Gestart op {war.startDate}</em></p>

                        <h2 className={styles.title}>Highlights</h2>
                        <div className={styles.highlights}>
                            <WarHighlight highlight="Meeste spelers gekilled" name={war.highlights.topKills} />
                            <WarHighlight highlight="Meeste capture points" name={war.highlights.topCaptures} />
                            <WarHighlight highlight="Meeste player damage" name={war.highlights.topDamage} />
                            <WarHighlight highlight="Meeste health regen" name={war.highlights.topRegen} />
                        </div>

                        <h2 className={styles.title}>Statistics</h2>
                        { war.statistics.length > 0 ? (
                                <div className={styles.statistics}>
                                    <WarTable data={war.statistics} />
                                </div>
                            ) : (
                                <div></div>
                            ) 
                        }
                    </>
                ) : (
                    <>
                        <h1 className={styles.warTitle}>War niet gevonden.</h1>
                    </>
                )
            }
        </div>
    );
};

export default WarStats;