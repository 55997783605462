import { useEffect, useState } from "react";
import styles from "./WarWinnerViewer.module.css";

const ImageMapping = {
    "bone-brigade": "/images/bone_brigade.png",
    "astral-accord": "/images/astral_accord.png",
    "static-syndicate": "/images/static_syndicate.png"
};

const NameMapping = {
    "bone-brigade": "Bone Brigade",
    "astral-accord": "Astral Accord",
    "static-syndicate": "Static Syndicate"
};

const WarWinnerViewer = (props) => {
    const { name, type } = props;

    const [winner, setWinner] = useState(null);

    useEffect(() => {
        const fetchWinner = async () => {
            try {
                const apiUrl = 'https://api.majestumkingdoms.nl';
                //const apiUrl = 'https://localhost:44319';
                const response = await fetch(apiUrl + '/api/battles/winner?name=' + name + '&type=' + type);
                if (!response.ok) {
                    throw new Error('Failed to fetch war winner');
                }
                const data = await response.text();
                setWinner(data);
            } catch (error) {
                console.error('Error fetching war winner data:', error);
                setWinner("none");
            }
        };

        fetchWinner();
    }, [name, type]);
    
    /* const winner = "sylvanenclave"; */

    if (!winner) {
        return (
            <div className={styles.loading}>
                <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                <p>Loading war winner...</p>
            </div>
        );
    }

    if (winner === "none") {
        return (
            <div className={styles.loading}>
                <i className="fa-solid fa-xmark"></i>
                <p>Geen winnaar gevonden</p>
            </div>
        );
    }

    return (
        <div className={styles.warWinnerViewer}>
            <img src={ImageMapping[winner]} alt={winner} />
            <div>Winnaar:</div>
            <div>{NameMapping[winner]}</div>
        </div>
    );
};

export default WarWinnerViewer;