import { Outlet } from "react-router-dom"
import NavBar from "../components/NavBar/NavBar";
import ThemeSwitch from "../components/ThemeSwitch/ThemeSwitch";

const RootLayout = () => {
    return (
        <div className="root-layout">
            <NavBar />
            <main>
                <Outlet />
            </main>
            <ThemeSwitch />
        </div>
    );
};

export default RootLayout;